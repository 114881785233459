import React from 'react';
import _ from 'lodash';
import { Select } from 'antd';

export const multi_static_select = ({ block, utils }) => {
  const placeholder = _.get(block, 'placeholder');
  return (
    <Select
      {...{
        ...(placeholder ? { placeholder: utils.renderBlock(placeholder) } : {}),
        options: _.get(block, 'options', []).map((option) => {
          return {
            value: _.get(option, 'value'),
            label: utils.renderBlock(_.get(option, 'text')),
          };
        }),
        mode: 'multiple',
        style: { width: '100%' },
      }}
    >
    </Select>
  );
};
