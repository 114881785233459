import React from 'react';
import _ from 'lodash';
import { DatePicker } from 'antd';
// import dayjs from 'dayjs';
import moment from 'moment';

export const datepicker = ({ block, utils }) => {
  const placeholder = _.get(block, 'placeholder');
  const defaultValue = _.get(block, 'initial_date');
  return (
    <DatePicker
      {...{
        ...(placeholder ? { placeholder: utils.renderBlock(placeholder) } : {}),
        ...(defaultValue ? { defaultValue: moment(defaultValue) } : {}),
      }}
    />
  );
};
