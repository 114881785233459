import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

import NotificationModelFormatter from '@uz/unitz-models/NotificationModel/formatter';
import NotificationModelRenderer from '@uz/unitz-models/NotificationModel/renderer';
import CourseRoomFormatter from '@uz/unitz-models/CourseRoomModel/formatter';

import React from 'react';
import NotificationModel from '@uz/unitz-models/NotificationModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import UserModelFormatter from '@uz/unitz-models/UserModel/formatter';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import IANDispatcherClient from '@vl/mod-clients/IANDispatcher';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const itemData = ctx.get('@item');
              const courseLink = _.get(itemData, 'payload.course.name', '');
              const course = _.get(itemData, 'payload.course');
              const courseId = _.get(course, 'id', '');
              const user_id = _.get(itemData, 'payload.purchase.user_id', '');
              const purchase_id = _.get(itemData, 'payload.purchase.id', '');
              const userInfo = usePromiseSource(
                async () => {
                  try {
                    const client = await getClient();
                    const data = await client.query(
                      gql`
                        query DataAll($id: String!) {
                          user(where: { id: { _eq: $id } }) {
                            profile {
                              avatar_url
                              display_name
                            }
                          }
                        }
                      `,
                      { id: user_id }
                    );
                    return _.get(data, 'user');
                  } catch (err) {
                    console.log(err);
                  }
                },
                null,
                [purchase_id, user_id]
              );

              const message = usePromiseSource(
                async () => {
                  try {
                    const data = await IANDispatcherClient.getClient().dispatch({
                      ...itemData,
                      type: _.get(itemData, 'type_id')
                    });
                    return data;
                  } catch (err) {
                    console.log(err);
                  }
                },
                null,
                []
              );

              const [seen, $seen] = React.useState(false);

              const courseInfo = usePromiseSource(
                async () => {
                  try {
                    if (!courseId) return null;
                    const client = await getClient();
                    const data = await client.query(
                      gql`
                        query DataAll($id: uuid!) {
                          course(where: { id: { _eq: $id } }) {
                            photo_url
                            name
                            id
                            slug
                          }
                        }
                      `,
                      { id: courseId }
                    );
                    return _.get(data, 'course');
                  } catch (err) {
                    console.log(err);
                  }
                },
                null,
                [courseId]
              );

              const courseInfoData = { course: _.get(courseInfo, '0', {}) };

              const onSeenNotification = async (item) => {
                try {
                  if (seen) return;
                  $seen(true);
                  const id = _.get(item, 'id', '');
                  const result = await NotificationModel.seenNotification({ id });
                  if (!result) {
                    $seen(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              };
              const userItem = _.get(userInfo, '0');

              React.useEffect(() => {
                $seen(_.get(itemData, 'seen'));
              }, [_.get(itemData, 'seen')]);

              const rtn = {
                ..._.merge({}, message, itemData),
                notificationId: _.get(itemData, 'id'),
                seen,
                course: {
                  displayName: CourseRoomFormatter.courseTitle(ctx)(courseInfoData),
                  avatarUrl: CourseRoomFormatter.courseAvatar(ctx)(courseInfoData),
                },
                time: NotificationModelFormatter.timeNow(ctx)(itemData),
                onSeenNotification,
                deleteNoti: async () => {
                  const data = await NotificationModel.deleteNotification({ id: _.get(itemData, 'id') });
                  if (data) {
                    ctx.apply('@pagination.onRefresh');
                  }
                },
              };
              return rtn;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
