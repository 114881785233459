import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { useRenderProps } from '@vl/hooks/useRenderProps';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';

import BaseNotification from '@uz/unitz-tool-pages/Notifications/Renderers/BaseNotification';

import FlatList from '@uz/unitz-components-web/FlatList';
import { Row, Col } from 'antd';

const renderItem = useRenderProps(
  ({ item, index }) => {
    return (
      <DIV forceCtx>
        <Col span={24}>
          {ctx.debug(() => {
            ctx.set('@item', item);
            ctx.set('@index', index);
          })}
          {renderPropsComposer(
            {
              matcher: () => true,
              render: () => <BaseNotification />,
            },
            () => null
          )(item)}
        </Col>
      </DIV>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

const View8 = () => (
  <DIV className="notificationListX">
    <div>
      <FlatList
        as={Row}
        data={ctx.get('@items', [])}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
      />
    </div>
  </DIV>
);

export default displayName(View8);
