import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import NotificationListX from '@uz/unitz-tool-components/NotificationListX';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import '@uz/unitz-components-web/BlockKit/loader/gatsby';
import '@uz/unitz-components-web/IAN/loader/gatsby';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <div className="mb-4 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolNotification.title')}
          </div>
          {renderPropsComposer(
            {
              matcher: (props) => !_.get(props, 'items.length') && !_.get(props, 'isLoading'),
              render: () => (
                <div className="noti-empty rounded-lg shadow bg-white500">
                  <Row justify="center" className="py-16">
                    <Col span={12}>
                      <div className="flex flex-col items-center justify-center opacity-50 select-none">
                        <div className="mb-4">
                          {gstyles.icons({
                            name: 'info',
                            size: 65,
                            fill: gstyles.colors.sub,
                          })}
                        </div>
                        <div className="mb-6 text-center text-base font-normal text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolNotification.empty_text')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
              render: () => (
                <div className="noti-loading flex items-center justify-center rounded-lg shadow bg-white500">
                  <LoadingScreen />
                </div>
              ),
            },
            {
              matcher: (props) => !!_.get(props, 'items.length') && !_.get(props, 'isLoading'),
              render: () => (
                <div className="noti-list rounded-lg shadow bg-white500">
                  <NotificationListX />
                </div>
              ),
            },
            () => null
          )(ctx.get('paginationModel'))}

          {console.log(ctx.get('paginationModel'))}
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
