import React from 'react';
import _ from 'lodash';
import { Checkbox, Row, Col } from 'antd';

export const checkboxes = ({ block, utils }) => {
  return (
    <Row>
      {_.map(_.get(block, 'options'), (option, index) => {
        const text = _.get(option, 'text');
        const description = _.get(option, 'description');
        const value = _.get(option, 'value');
        return (
          <Col {...{ span: 24 }} key={`${index}_${value}`}>
            <Checkbox value={value}>
              {!!text && <strong>{utils.renderBlock(text)}</strong>}
              {!!description && utils.renderBlock(description)}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
};
