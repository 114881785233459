import React from 'react';
import _ from 'lodash';
import { Form, Input } from 'antd';

export const input = ({ block, utils }) => {
  const placeholder = _.get(block, 'placeholder');
  const label = _.get(block, 'label');
  const type = _.get(block, 'element.type');
  let InputComponent;
  if (type === 'plain_text_input') {
    const multiline = _.get(block, 'element.multiline');
    if (multiline) {
      InputComponent = Input.TextArea;
    } else {
      InputComponent = Input;
    }
  }
  if (!InputComponent) return null;
  return (
    <Form.Item
      {...{
        ...(label ? { label: utils.renderBlock(label) } : {}),
      }}
    >
      <InputComponent
        {...{
          ...(placeholder ? { placeholder: utils.renderBlock(placeholder) } : {}),
        }}
      />
    </Form.Item>
  );
};
