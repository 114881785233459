import React from 'react';
import _ from 'lodash';
import Handlebars from 'handlebars';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';

import BlockKitTheme from './themes/web';
import { loader } from './loader';

const getThemes = _.memoize(() => {
  const themes = {
    web: BlockKitTheme,
  };
  return themes;
});

const getTplById = _.memoize((tpl_id) => {
  const tpl = loader.getTemplate(tpl_id);
  return tpl;
});

const substitutesData = (tpl_json, data) => {
  const templateStr = JSON.stringify({ tpl_json });
  const template = Handlebars.compile(templateStr);
  const rtnStr = template(data);
  const rtnJson = JSON.parse(rtnStr);
  return _.get(rtnJson, 'tpl_json');
};

export const loadTheme = _.memoize((name = 'web') => {
  const BlockKitTheme = _.get(getThemes(), name);
  const utils = {
    BlockKitTheme,
  };
  _.extend(utils, {
    renderBlock: (block, index) => {
      const blockType = _.get(block, 'type');
      const renderers = utils.getRenderers();
      const render = _.get(renderers, blockType);
      if (_.isFunction(render)) {
        return (
          <React.Fragment key={`${index}`}>
            {render({ block, utils })}
          </React.Fragment>
        );
      }
      console.log('null render blockType', blockType, render);
      return null;
    },
    getRenderers: () => {
      const BlockKitTheme = utils.getTheme();
      const renderers = BlockKitTheme.getRenderers();
      return renderers;
    },
    getTheme: () => {
      return utils.BlockKitTheme;
    },
    renderTemplate: (tpl_id, data) => {
      return (
        <AsyncRender render={async () => {
          const tpl = await getTplById(tpl_id);
          if (!tpl) return null;
          const tplJson = substitutesData(tpl, {
            ...data,
          });
          if (!tplJson) return null;
          const blocks = _.get(tplJson, 'blocks', []);
          return (<>
            {_.map(blocks, utils.renderBlock)}
          </>);
        }}/>
      );
    }
  });
  return utils;
});

export const renderTemplate = (tpl_id, data) => {
  const theme = loadTheme();
  return theme.renderTemplate(tpl_id, data);
};
