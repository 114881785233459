import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'antd';

export const section = ({ block, utils }) => {
  const text = _.get(block, 'text');
  const accessory = _.get(block, 'accessory');
  const fields = _.get(block, 'fields', []);
  return (
    <div>
      <div className="flex justify-between">
        {!!text &&
          <div>
            {utils.renderBlock(text)}
          </div>
        }
        {!!accessory &&
          <div>
            {utils.renderBlock(accessory, 0)}
          </div>
        }
      </div>
      {!!_.get(fields, 'length') &&
        <Row className="flex flex-wrap" gutter={8}>
          {_.map(fields, (field, index) => {
            return (
              <Col key={`${index}`} {...{ span: 12 }}>{utils.renderBlock(field, index)}</Col>
            );
          })}
        </Row>
      }
    </div>
  );
};
