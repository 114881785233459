const _ = require('lodash');

const { dispatchers } = require('./dispatchers');
const helpers = require('./helpers');
const utils = require('./utils');
const models = require('./models');
const clients = require('./clients');

const queryCtxData = async ({ payload }, ctx) => {
  const { dispatcher } = ctx;
  let query;
  let vars;
  if (_.isFunction(dispatcher.getQuery)) {
    query = await dispatcher.getQuery({ payload }, ctx);
  }
  if (_.isFunction(dispatcher.getVars)) {
    vars = await dispatcher.getVars({ payload }, ctx);
  }
  const ctxData = await clients.hasuraClient.getClient().query(query, vars);
  return ctxData;
};

// config dispatcher loader
dispatchers.load = (type) => {
  return clients.remoteConfigClient.getClient().require(`IANWeb/dispatchers/${type}.js`);
};

exports.getClient = _.memoize(() => {
  return {
    async dispatch(message) {
      let rtn;
      const type = _.get(message, 'type');
      let dispatcher = _.get(dispatchers, type);
      if (!dispatcher) {
        // try to load dispatcher from remote config
        try {
          dispatcher = await dispatchers.get(type);
          console.log(`found dispatcher for ${type} from remote-config`);
        } catch (err) {
          console.log(`load dispatcher ${type} error`);
        }
      }
      if (_.isFunction(dispatcher)) {
        rtn = await dispatcher(message);
      } else if (_.isPlainObject(dispatcher) && _.isFunction(dispatcher.dispatch)) {
        const ctx = {
          dispatcher,
          ctxData: {},
          helpers,
          utils,
          models,
          clients,
        };
        ctx.ctxData = await queryCtxData(message, ctx);

        try {
          rtn = await dispatcher.dispatch(message, ctx);
        } catch (err) {
          console.log('dispatch fcm error', err);
        }

        if (_.isFunction(dispatcher.effect)) {
          try {
            await dispatcher.effect(message, ctx);
          } catch (err) {
            console.log('effect fcm error', err);
          }
        }
      } else {
        // not handled notification
        console.log(`Could not find dispatcher for "${type}"`);
      }
      return rtn;
    },
  };
});
