const _ = require('lodash');

const DEFAULT_TIMEZONE = '+07:00';
const DEFAULT_LANG = 'vi';
const userLangMapping = {};

const LANG_MAP_FULL = {
  vi: '',
  en: 'en_US',
};

exports.getUserTimezone = () => {};
exports.getUserLang = () => {};

exports.forUser = async (userId) => {
};

exports.useConfigEnv = () => {};
