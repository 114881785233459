import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

import GetNotification from 'unitz-gql/notification/GetNotification.gql';

graphme.GqlBuilder.loadDocument({
  GetNotification,
});

class NotificationModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'NotificationModel',
    schema: {
      is_active: Boolean,
      id: String,
    },

    key: 'id',

    baseQuery: 'GetNotification',

    GQL_ACTIONS: {
      FIND: 'notification',
      GET: 'notification_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static async seenNotification({ id }) {
    const query = hasuraClient.gql`
      mutation seenNotification($id: uuid!) {
        notification: update_notification_by_pk(pk_columns: {id: $id}, _set: {seen: true}) {
          id
          seen
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        id,
      });

      const data = _.get(rtn, 'notification.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async deleteNotification({ id }) {
    const query = hasuraClient.gql`
      mutation seenNotification($id: uuid!) {
        notification: update_notification_by_pk(pk_columns: {id: $id}, _set: {deleted: "1"}) {
          id
          seen
          deleted
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        id,
      });

      const data = _.get(rtn, 'notification.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async seenAll({ owner_id }) {
    const query = hasuraClient.gql`
      mutation seenNotification($owner_id: String!) {
        notification: update_notification(where: {owner_id: {_eq: $owner_id}}, _set: {seen: true}) {
          affected_rows
          returning {
            id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        owner_id,
      });

      const data = _.get(rtn, 'notification.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ NotificationModel });

export default NotificationModel;
