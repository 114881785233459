import moment from 'moment';
import _ from 'lodash';
import { formatFullDateDisplay, formatPaymentHistoryTime } from '@vl/mod-utils/dateFormatter';
import { durationFormatter, formatVND } from '@vl/mod-utils/currencyFormatter';

export const formatter = {
  createdAtTxt: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'profile.created_at', '');
    if (!createdAt) return '';
    return ctx.apply('i18n.t', 'Advisor.joinedSince', { dateStr: moment(createdAt).format('Do MMM, YYYY') });
  },
  profileId: () => (itemData) => {
    return _.get(itemData, 'profile.id', '');
  },
  displayName: () => (itemData) => {
    return _.get(itemData, 'profile.display_name', '');
  },

  userDisplayName: () => (itemData) => {
    return _.get(itemData, 'profile.display_name', '');
  },

  profilePhoneNumber: () => (itemData) => {
    return _.get(itemData, 'profile.phone_number', '');
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);

    return (
      _.get(itemData, 'profile.avatar_url', `https://ui-avatars.com/api/?name=${displayName}`) ||
      `https://ui-avatars.com/api/?name=${displayName}`
    );
  },
  titlePaymentHistory: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'created_at', '');
    if (!createdAt) return '';

    return formatPaymentHistoryTime(ctx)(createdAt);
  },

  iconService: (ctx) => (itemData) => {
    const idService = _.get(itemData, 'service_pricing.detail.service.service.id', '');
    if (!idService) return '';

    const icon = _.first(_.split(idService, '_'));

    return icon === 'video' ? 'camera' : icon;
  },
  nameService: (ctx) => (itemData) => {
    const name = _.get(itemData, 'service_pricing.detail.service.service.name', '');
    if (!name) return '';

    return name;
  },

  durationTime: (ctx) => (itemData) => {
    const type = formatter.typeService(ctx)(itemData);
    let duration_amount = 0;
    if (type === 'course') {
      duration_amount = _.get(itemData, 'transaction_purchases.0.purchase.courses.0.course.session_duration', 0);
    } else if (type === 'call') {
      duration_amount = _.get(itemData, 'service_pricing.detail.per_amount', 0);
    }
    if (!duration_amount) return '';
    return ctx.apply('i18n.t', 'AdvisorInCome.inComeTextTime', { num: _.ceil(duration_amount / 60) });
  },

  timeService: (ctx) => (itemData) => {
    const timeService = _.get(itemData, 'service_pricing.detail.per_amount', 0);
    if (!timeService) return 0;

    return `${Math.round(timeService / 60)} ${ctx.apply('i18n.t', 'time.minute')}`;
  },

  timeServiceTxt: (ctx) => (itemData) => {
    const timeService = _.get(itemData, 'service_pricing.detail.per_amount', 0);
    if (!timeService) return 0;

    return ctx.apply('i18n.t', 'UserWallet.packageName', {
      time: Math.round(timeService / 60),
    });
  },

  priceService: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'service_pricing.detail.price_amount', 0);
    if (!price_amount) return 0;
    const currency = _.get(itemData, 'service_pricing.detail.price_currency', 0);

    return `${formatVND(price_amount, currency)}`;
  },
  dateService: (ctx) => (itemData) => {
    const created_at = _.get(itemData, 'created_at', '');
    if (!created_at) return '';
    return formatFullDateDisplay(created_at);
  },
  totalMoney: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'service_pricing.detail.price_amount', 0);
    const timeService = _.get(itemData, 'service_pricing.detail.per_amount', 0);
    if (!price_amount || !timeService) return 0;
    const currency = _.get(itemData, 'service_pricing.detail.price_currency', 0);

    return `${formatVND((price_amount * timeService) / 60, currency)}`;
  },
  descriptionService: (ctx) => (itemData) => {
    const tx_comment = _.get(itemData, 'tx_comment', '');
    return tx_comment;
  },

  serviceKind: (ctx) => (itemData) => {
    const kind = _.get(itemData, 'service_pricing.detail.service.service.kind', '');
    if (!kind) return '';
    return kind;
  },

  typeService: (ctx) => (itemData) => {
    const kind = _.get(itemData, 'service_pricing.detail.service.service.kind', '');
    if (kind) return 'call';
    if (_.get(itemData, 'transaction_purchases.0.purchase.courses.0.course.id')) {
      return 'course';
    }
    return '';
  },

  statusSessionService: (ctx) => (itemData) => {
    const status = _.get(itemData, 'status_latest.status', 'processing');
    return status;
  },

  statusService: (ctx) => (itemData) => {
    const status = _.get(itemData, 'session_detail.status', '');
    if (!status) return 'call';
    return status;
  },

  courseName: (ctx) => (itemData) => {
    const name = _.get(itemData, 'transaction_purchases.0.purchase.courses.0.course.name', '');
    return name;
  },

  advisorDisplayName: (ctx) => (itemData) => {
    const display_name = _.get(itemData, 'service_pricing.detail.service.advisor.profile.display_name', '');
    if (!display_name) return '';
    return display_name;
  },

  packageValue: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'service_pricing.detail.per_amount', 0);
    const price_amount = _.get(itemData, 'service_pricing.detail.price_amount', 0);
    if (!price_amount || !price_amount) return '';
    return `${formatVND(price_amount)}/ ${Math.round(per_amount / 60)} ${ctx.apply('i18n.t', 'time.minute')}`;
  },

  timeCall: (ctx) => (itemData) => {
    const duration_amount = _.get(itemData, 'session_detail.duration_amount', 0);

    if (!duration_amount) return '';
    return durationFormatter(duration_amount * 1000);
  },

  urlUpload: () => (itemData) => {
    const userId = _.get(itemData, 'user_id', '');
    const name = _.get(itemData, 'photoName', '');
    const parts = `${name}`.split('.');
    const ext = parts.pop() || 'ext';
    let urlName = _.snakeCase(_.deburr(parts.join(''))).replaceAll('_', '');
    const subFolder = _.get(itemData, 'subFolder', 'Photo');
    return `${userId}/${subFolder}/${new Date().getTime()}${urlName}.${ext}`;
  },
  statusUserTester: () => (itemData) => {
    const item = _.get(itemData, 'tester');
    const statusData = {
      isActive: _.get(item, 'status') === 'active',
      isInActive: _.get(item, 'status') === 'inactive',
    };
    return statusData;
  },
};

export default formatter;
