import _ from 'lodash';
import path from 'path';

const store = {};
export const loader = {};

const ORIGIN = 'https://zoom.unitz.app';
const getOrigin = () => {
  const origin = _.get(window, 'location.origin');
  if (_.startsWith(origin, 'http://localhost')) {
    return ORIGIN;
  }
  return '';
};

const LOCALES = ['en', 'vi', 'de'];

const FULL_LOCALES_MAPPING = {
  en: 'en_US',
  vi: 'vi_VN',
  de: 'de_DE',
};

const getLocalePrefix = () => {
  const pathname = _.get(window, 'location.pathname');
  const paths = _.split(_.trim(pathname, '/'), '/');
  const locale = _.first(paths);
  if (LOCALES.includes(locale)) {
    return `${locale}`;
  }
  return '';
};

const suffixFilenameWithLocale = (filename) => {
  const parts = _.split(filename, '/');
  const extname = path.extname(filename);
  const basename = path.basename(filename, extname);
  const locale = getLocalePrefix();
  const localeSuffix = _.get(FULL_LOCALES_MAPPING, locale) || '';
  if (localeSuffix && parts.length) {
    const suffixBaseName = `${basename}_${localeSuffix}${extname}`;
    parts[parts.length - 1] = suffixBaseName;
    return parts.join('/');
  }
  return filename;
};

_.extend(loader, {
  setTemplate: (tplId, content) => {
    _.set(store, [tplId], content);
  },
  getTemplate: (tplId) => {
    if (!loader.hasTemplate(tplId)) {
      return new Promise((resolve, reject) => {
        loader.loadTemplate(tplId).then((res) => {
          return res.json().then((data) => {
            loader.setTemplate(tplId, data);
            return data;
          });
        }).catch((err) => {
          // console.log('error', err);
          loader.setTemplate(tplId, null);
          resolve(null);
        });
      });
    }
    return _.get(store, [tplId]);
  },
  hasTemplate: (tplId) => {
    return _.has(store, [tplId]);
  },
  getStore: () => {
    return store;
  },
  loadTemplate: (tplId) => {
    const tpl_url = `${getOrigin()}${suffixFilenameWithLocale(`/files/remote-config/IANWeb/templates/${tplId}.json`)}`;
    return fetch(tpl_url);
  }
});
