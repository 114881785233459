import React from 'react';
import _ from 'lodash';
import { Typography } from 'antd';

const { Title } = Typography;

export const header = ({ block, utils }) => {
  const text = _.get(block, 'text');
  return <Title level={4}>{utils.renderBlock(text)}</Title>;
};
