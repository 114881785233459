import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import { loadTheme } from '@uz/unitz-components-web/BlockKit';

const { renderTemplate } = loadTheme();

const View8 = () => (
  <DIV className="component">
    <div
      className={cx({
        'bg-brandb-lightest cursor-pointer': !ctx.get('@item.seen', ''),
        'bg-white500': !!ctx.get('@item.seen', ''),
      })}
    >
      <Row className="px-4 min-lg:px-6" onClick={() => ctx.apply('@item.onSeenNotification', ctx.get('@item'))}>
        <Col xs={24} lg={4}>
          <div className="flex items-center">
            <h4 className="mt-4 text-sm font-normal text-sub">{ctx.get('@item.time')}</h4>
          </div>
        </Col>
        <Col xs={24} lg={ctx.get('@item.seen') ? 16 : 18}>
          <div className="mt-0 min-lg:mt-4">
            <div className="mb-0 text-sm font-normal"></div>
            {/* <div className="text-sm font-normal">{ctx.get('@item.content')}</div> */}
            <div className="text-sm font-normal">{renderTemplate(ctx.get('@item.type_id'), ctx.get('@item'))}</div>
          </div>
        </Col>
        <Col
          className={cx('block', {
            ' hidden': !ctx.get('@item.seen'),
          })}
          xs={0}
          lg={ctx.get('@item.seen') ? 2 : 0}
        >
          <div className="mt-4 text-brandb500 font-semibold text-right">
            {ctx.apply('i18n.t', 'UserNotification.Seen')}
          </div>
        </Col>
        <Col xs={0} lg={ctx.get('@item.seen') ? 2 : 2}>
          <div
            className="mt-4 text-red500 font-semibold cursor-pointer text-right"
            onClick={() => ctx.apply('@item.deleteNoti')}
          >
            {ctx.apply('i18n.t', 'UserNotification.Delete')}
          </div>
        </Col>
      </Row>
    </div>

    <div className="border-b mx-4 min-lg:mx-6 border-divider"></div>
  </DIV>
);

export default displayName(View8);
