const _ = require('lodash');

const dispatchers = {};

_.extend(dispatchers, {
  set: (tplId, content) => {
    _.set(dispatchers, [tplId], content);
  },
  get: (tplId) => {
    if (!dispatchers.has(tplId)) {
      return new Promise((resolve) => {
        dispatchers.load(tplId).then((res) => {
          const data = res;
          dispatchers.set(tplId, data);
          return data;
        }).catch((err) => {
          console.log('error', err);
          dispatchers.set(tplId, null);
          resolve(null);
        });
      });
    }
    return _.get(dispatchers, [tplId]);
  },
  has: (tplId) => {
    return _.has(dispatchers, [tplId]);
  },
  getStore: () => {
    return dispatchers;
  },
  load: (tplId) => {
    // return fetch(tplId);
    return null;
  }
});

exports.dispatchers = dispatchers;
