import React from 'react';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const mrkdwn = ({ block, utils }) => {
  const type = _.get(block, 'type');
  const text = _.get(block, 'text');
  console.log('texttexttext', text);
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]}>
      {text}
    </ReactMarkdown>
  );
};
