import React from 'react';
import _ from 'lodash';
import { Radio, Space } from 'antd';

export const radio_buttons = ({ block, utils }) => {
  return (
    <Radio.Group>
      <Space direction="vertical">
        {_.map(_.get(block, 'options'), (option, index) => {
          const text = _.get(option, 'text');
          const description = _.get(option, 'description');
          const value = _.get(option, 'value');
          return (
            <Radio value={value} key={`${index}_${value}`}>
              {!!text && <strong>{utils.renderBlock(text)}</strong>}
              {!!description && utils.renderBlock(description)}
            </Radio>
          );
        })}
      </Space>
    </Radio.Group>
  );
};
