import React from 'react';
import _ from 'lodash';
import { Button, Dropdown } from 'antd';

export const overflow = ({ block, utils }) => {
  return (
    <Dropdown
      {...{
        menu: _.get(block, 'options', []).map((option) => {
          return {
            value: _.get(option, 'value'),
            key: _.get(option, 'value'),
            label: utils.renderBlock(_.get(option, 'text')),
          };
        }),
      }}
    >
      <Button size="small"><span>{`  ...  `}</span></Button>
    </Dropdown>
  );
};
