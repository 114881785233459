import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  notificationListX: {
    rules: [
      [
        'data',
        {
          data: {
            '@items': hook((ctx) => {
              const items = ctx.get('paginationModel.items');
              return items;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
