import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import _ from 'lodash';
import NotificationModel from '@uz/unitz-models/NotificationModel';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModel = React.useMemo(() => {
                const userId = ctx.apply('authModel.getUserId');
                const condition = `
                  where: {
                    owner_id: {_eq: "${userId}"},
                    type_id: {_like: "%user%"}
                    deleted: {_eq: "0"}
                  }
                  order_by: { created_at: desc }
                `;
                const dataQuery = `
                notification(${condition}) {
                  created_at
                  id
                  is_active
                  owner_id
                  payload
                  type_id
                  seen
                  seen_at
                } 
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: NotificationModel,
                  dataQuery,
                  aggQuery: `
                    notification_aggregate(${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 20,
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };
              ctx.apply('REF.setRef', 'NotificationPagingModel', pagination);
              ctx.apply('loadingModel.clearLoading', 'fetchNotification');
              // console.log('items', pagingModel.useState('items'), pagingModel.useState('total'));
              return pagination;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
