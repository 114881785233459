import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';

export const button = ({ block, utils }) => {
  const text = _.get(block, 'text');
  const type = _.get(block, 'text');
  const url = _.get(block, 'url');
  return (
    <Button {...{
      ...(url ? { href: url, type: 'link' } : {}),
    }}>
      {!!text &&
        <span>
          {utils.renderBlock(text)}
        </span>
      }
    </Button>
  );
};
