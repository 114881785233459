import React from 'react';
import _ from 'lodash';
import { Image } from 'antd';

export const image = ({ block, utils }) => {
  const url = _.get(block, 'image_url');
  const alt_text = _.get(block, 'alt_text');
  return (
    <Image {...{
      ...(url ? { src: url, alt: alt_text || 'image' } : {}),
    }} />
  );
};
