const {
  formatCallDuration,
  formatSessionOccurence,
  formatCallDurationWithI18n,
  formatSessionOccurenceWithI18n,
  formatAttendeeWithI18n,
  formatSessionSlotTime,
  START_TIME_FORMAT,
  START_TIME_FULL_FORMAT,
  START_DAY_FULL_FORMAT,
} = require('@vl/mod-utils/datetime');
// const { formatCurrencySSR } = require('@vl/mod-utils/currency');
const { flattenGet } = require('@vl/mod-utils/flattenGet');
// const { renderer } = require('@vl/mod-utils/rendererQuill');

module.exports = {
  _: require('lodash'),
  moment: require('moment'),
  // admin: require('firebase-admin'),
  formatAttendeeWithI18n,
  formatCallDuration,
  formatSessionOccurence,
  formatSessionOccurenceWithI18n,
  // formatCurrencySSR,
  flattenGet,
  formatCallDurationWithI18n,
  formatSessionSlotTime,
  START_TIME_FORMAT,
  START_TIME_FULL_FORMAT,
  START_DAY_FULL_FORMAT,
  // rendererQuill: renderer,
};
