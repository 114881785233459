import React from 'react';
import _ from 'lodash';

export const context = ({ block, utils }) => {
  const elements = _.get(block, 'elements', []);
  return (
    <div>
      {!!_.get(elements, 'length') &&
        <div className="flex flex-wrap">
          {_.map(elements, (element, index) => {
            return (
              <React.Fragment key={`${index}`}>{utils.renderBlock(element, index)}</React.Fragment>
            );
          })}
        </div>
      }
    </div>
  );
};
